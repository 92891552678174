import {
  ApolloClient,
  ApolloProvider,
  from,
  InMemoryCache,
} from '@apollo/client';
import { BatchHttpLink } from '@apollo/client/link/batch-http';
import { onError } from '@apollo/client/link/error';
import { ReactNode, useEffect, useMemo, useState } from 'react';

const dateReader = (date: string) =>
  typeof date !== 'undefined' ? new Date(date) : undefined;

export const ApolloLayer = ({
  children,
  apiUrl,
}: {
  apiUrl: string;
  children: ReactNode;
}) => {
  const [error, setError] = useState<string | null>(null);

  const apolloClient = useMemo(() => {
    const httpLink = new BatchHttpLink({
      uri: apiUrl,
      credentials: 'include',
    });
    const errorLink = onError(
      ({ operation: { operationName }, graphQLErrors, networkError }) => {
        if (graphQLErrors) {
          graphQLErrors.forEach(({ message }) => {
            setError(
              `[GraphQL error]: Operation: "${operationName}", Message: ${message}"`,
            );
          });
        }
        if (networkError) {
          setError(
            `[Network error]: Operation: "${operationName}", Error: "${networkError}"`,
          );
        }
      },
    );
    const apolloClient = new ApolloClient({
      cache: new InMemoryCache({
        // The below (as suggested here
        // https://www.graphile.org/postgraphile/node-id/ )
        // causes silent failures if `nodeId` isn't present
        // `dataIdFromObject: object => object.nodeId || null,`
        // Instead, for any types causing cache issues, add a keyfields prop
        // with `nodeId` as the key. For more info see the Apollo docs:
        // https://www.apollographql.com/docs/react/caching/cache-configuration/#customizing-cache-ids

        typePolicies: {
          Allocation: {
            keyFields: ['nodeId'],
          },
          TransactionGroup: {
            keyFields: ['nodeId'],
          },
          TransactionAllowance: {
            keyFields: ['nodeId'],
          },
          Team: {
            keyFields: ['nodeId'],
          },
          SellerOnboarding: {
            keyFields: ['nodeId'],
          },
          BuyerOnboarding: {
            keyFields: ['nodeId'],
          },
          BuyerOffer: {
            keyFields: ['nodeId'],
          },
          Company: {
            keyFields: ['nodeId'],
          },
          EquityGrant: {
            keyFields: ['nodeId'],
            fields: {
              grantDate: {
                read: dateReader,
              },
            },
          },
          EquitySummary: {
            keyFields: ['id'],
          },
          SellerOffer: {
            keyFields: ['nodeId'],
          },
          TransactionTaxRate: {
            keyFields: ['nodeId'],
          },
          EquityTypeInfo: {
            keyFields: ['nodeId'],
          },
          CurrentUser: {
            keyFields: ['userId'],
          },
          TransactionFee: {
            keyFields: ['nodeId'],
            fields: {
              rate: {
                read(val: string) {
                  return typeof val !== 'undefined' ? Number(val) : undefined;
                },
              },
            },
          },
          TaxVariable: {
            fields: {
              saleTaxRate: {
                read(val: string) {
                  return typeof val !== 'undefined' ? Number(val) : undefined;
                },
              },
              exerciseTaxRate: {
                read(val: string) {
                  return typeof val !== 'undefined' ? Number(val) : undefined;
                },
              },
            },
          },
          Currency: {
            keyFields: false,
            fields: {
              amount: {
                read(val: string) {
                  return typeof val !== 'undefined' ? Number(val) : undefined;
                },
              },
            },
          },
          Employment: {
            fields: {
              employmentStart: {
                read: dateReader,
              },
              employmentEnd: {
                read: dateReader,
              },
            },
          },
          LiquidityEvent: {
            keyFields: ['nodeId'],
            fields: {
              sellerOpening: {
                read: dateReader,
              },
              sellerOfferDeadline: {
                read: dateReader,
              },
              sellerExecuteDeadline: {
                read: dateReader,
              },
              sellerClosing: {
                read: dateReader,
              },
              sellerPaymentDeadline: {
                read: dateReader,
              },
            },
          },
        },
      }),
      link: from([errorLink, httpLink]),
    });
    return apolloClient;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (error) {
      // We throw an error here so it'll get caught by the <ErrorBoundary>
      throw new Error(error);
    }
  }, [error]);

  return <ApolloProvider client={apolloClient}>{children}</ApolloProvider>;
};
